frappe.ui.form.ControlAttach = class ControlAttach extends frappe.ui.form.ControlData {
	make_input() {
		let me = this;
		this.$input = $('<button class="btn btn-default btn-sm btn-attach">')
			.html(`<i class="fa fa-upload fa-1x" style="color:#a9a9a9; margin-left: 5px;"></i> <span class="font-bold">${__("Upload JPG and PDF")}</span>
`)
			.prependTo(me.input_area)
			.on({
				click: function () {
					me.on_attach_click();
				},
				attach_doc_image: function () {
					me.on_attach_doc_image();
				},
			});
		this.$value = $(
			`<div class="attached-file flex justify-between align-center">
				<div class="ellipsis">
				${frappe.utils.icon("es-line-link", "sm")}
					<a class="attached-file-link" target="_blank"></a>
				</div>
				<div>
					<a class="btn btn-xs btn-default" data-action="reload_attachment">${__("Reload File")}</a>
					<a class="btn btn-xs btn-default" data-action="clear_attachment">${__("Clear")}</a>
				</div>
			</div>`
		)
			.prependTo(me.input_area)
			.toggle(false);
		this.input = this.$input.get(0);
		this.set_input_attributes();
		this.has_input = true;

		frappe.utils.bind_actions_with_object(this.$value, this);
		this.toggle_reload_button();
	}
	clear_attachment() {
		let me = this;
		if (this.frm) {
			me.parse_validate_and_set_in_model(null);
			me.refresh();
			me.frm.attachments.remove_attachment_by_filename(me.value, async () => {
				await me.parse_validate_and_set_in_model(null);
				me.refresh();
				me.frm.doc.docstatus == 1 ? me.frm.save("Update JPEG and PDF only ") : me.frm.save();
			});
		} else {
			this.dataurl = null;
			this.fileobj = null;
			this.set_input(null);
			this.parse_validate_and_set_in_model(null);
			this.refresh();
		}
	}
	reload_attachment() {
		if (this.file_uploader) {
			this.file_uploader.uploader.upload_files();
		}
	}
	on_attach_click() {
		this.set_upload_options();
		this.file_uploader = new frappe.ui.FileUploader(this.upload_options);
	}
	on_attach_doc_image() {
		this.set_upload_options();
		this.upload_options.restrictions.allowed_file_types = ["image/*"];
		this.file_uploader = new frappe.ui.FileUploader(this.upload_options);
	}
	set_upload_options() {
		let options = {
			allow_multiple: false,
			on_success: (file) => {
				this.on_upload_complete(file);
				this.toggle_reload_button();
			},
			restrictions: {},
		};

		if (this.frm) {
			options.doctype = this.frm.doctype;
			options.docname = this.frm.docname;
			options.fieldname = this.df.fieldname;
			options.make_attachments_public = this.df.make_attachment_public
				? 1
				: this.frm.meta.make_attachments_public;
		}

		if (this.df.options) {
			Object.assign(options, this.df.options);
		}
		this.upload_options = options;
	}

	set_input(value, dataurl) {
		this.last_value = this.value;
		this.value = value;
	
		if (this.value) {
			this.$input.toggle(false);
	
			let file_url_parts = this.value.match(/^([^:]+),(.+):(.+)$/);
			let filename;
			if (file_url_parts) {
				filename = file_url_parts[1];
				dataurl = file_url_parts[2] + ":" + file_url_parts[3];
			}
	
			let file_name = this.value.split('/').pop();
			let file_url = dataurl || this.value;
	
			let action_buttons = `
				<div style="display: flex; gap: 10px; align-items: center;">    
					<a href="${file_url}"
						class="view-icon"
						target="_blank"
						style="text-decoration: none;">
						<i class="fa fa-eye fa-1x" style="color: #3498db;"></i> View
					</a>
					<a href="${file_url}"
					   download="${file_name}"
					   class="download-icon"
					   style="text-decoration: none;">
						<i class="fa fa-download fa-1x" style="color: #218838;"></i> Download
					</a>
					<a href="javascript:void(0);" class="reupload-icon">
						<i class="fa fa-upload fa-1x" style="color: #f39c12;"></i> Re-Upload
					</a>
					<span class="file-name">${file_name}</span>
				</div>`;
	
			this.$value
				.toggle(true)
				.html(action_buttons);
	
			// Bind the re-upload functionality
			this.$value.find('.reupload-icon').on('click', () => {
				this.on_attach_click();
			});
		} else {
			this.$input.toggle(true);
			this.$value.toggle(false);
		}
	}

	get_value() {
		return this.value || null;
	}

	async on_upload_complete(attachment) {
		if (this.frm) {
			await this.parse_validate_and_set_in_model(attachment.file_url);
			this.frm.attachments.update_attachment(attachment);
			this.frm.doc.docstatus == 1 ? this.frm.save("Update") : this.frm.save();
		}
		this.set_value(attachment.file_url);
	}

	toggle_reload_button() {
		this.$value
			.find('[data-action="reload_attachment"]')
			.toggle(this.file_uploader && this.file_uploader.uploader.files.length > 0);
	}
};
